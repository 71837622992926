<template>
    <form
        class="st-related-application form"
        novalidate="novalidate"
        :id="formId"
    >
        <label class="form-item-label" v-if="showLabel">
            {{ label }}
        </label>
        <div v-if="!viewMode">
            <b-overlay :show="showLoading" rounded="sm">
                <div class="row">
                    <div class="col col-12 col-md-4">
                        <div class="form-group">
                            <label
                                >{{
                                    fields.allocated_document_series.label
                                }}
                                *</label
                            >
                            <st-search
                                customClass="border border-secondary"
                                v-model="model[fields.allocated_document_series.name]"
                                :options="options"
                                :ref="fields.allocated_document_series.name"
                                :name="fields.allocated_document_series.name"
                                :searchFnc="updateOptions"
                                :listHeader="$t('RELATED_APPLICATION.SEARCH_DESCRIPTION')"
                                :disabled="viewMode"
                                @selected="onInputChange"
                            ></st-search>
                            <div
                                class="fv-plugins-message-container"
                                v-if="
                                    applicationNotFound &&
                                    !isValid
                                "
                            >
                                <div class="fv-help-block">
                                    {{ searchCustomNotFoundMessage }}
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col col-12 col-md-4">
                        <div class="form-group">
                            <label>{{ fields.issued_date.label }} *</label>
                            <st-input-text
                                :name="fields.issued_date.name"
                                v-model="model[fields.issued_date.name]"
                                setValidation
                                :maxLength="4"
                                :disabled="disabled"
                            >
                            </st-input-text>
                        </div>
                    </div>
                    <div class="col col-12 col-md-4">
                        <div class="form-group">
                            <label>{{ fields.issued_by.label }} *</label>
                            <st-select
                                v-model="model[fields.issued_by.name]"
                                :ref="fields.issued_by.name"
                                :name="fields.issued_by.name"
                                :field="fields.issued_by"
                                :formValidation="fv"
                                :disabled="disabled"
                            />
                        </div>
                    </div>
                </div>
                <!-- in prima faza: nu o sa avem file upload daca e setata optiunea de a incarca documente externe -->
                <div class="row" v-if="showDocumentUpload">
                    <div class="col col-10 col-md-4">
                        <div class="form-group">
                            <st-file
                                :viewMode="viewModeDocument"
                                :maxFileSize="Number(maxFileSize)"
                                :acceptedFileTypes="acceptedFileTypes"
                                :maxFiles="Number(maxFiles)"
                                :value="documents"
                                @change="onFileChange"
                                :wasValidated="wasValidated"
                                :isValid="isValid"
                                :token="relatedDocFileToken"
                                :formValidation="fv"
                            ></st-file>
                        </div>
                    </div>
                    <div class="col col-2" v-if="validFileSign" >
                        <i class="fas fa-check-circle valid-file text-success mt-4"></i>
                    </div>
                </div>
            </b-overlay>
        </div>
        <div v-else class="row">
            <div class="col col-12">
                <label>{{ fields.allocated_document_series.label }}:</label>
                <span v-if="linkToIssuedDocuments" class="ml-4 font-weight-bold">
                    <b-link @click.self.prevent="goToLink">
                        {{ model[fields.allocated_document_series.name] }}
                    </b-link>
                </span>
                <span v-else class="ml-4 font-weight-bold">
                        {{ model[fields.allocated_document_series.name] }}
                </span>
            </div>
            <div class="col col-12">
                <label>{{ fields.issued_date.label }}:</label>
                <span class="ml-4 font-weight-bold">{{
                    model[fields.issued_date.name]
                }}</span>
            </div>
            <div class="col col-12">
                <label>{{ fields.issued_by.label }}:</label>
                <span class="ml-4 font-weight-bold">{{
                    model[fields.issued_by.name]
                }}</span>
            </div>
            <div v-if="documents.length" class="col col-6">
                <st-file :viewMode="viewMode" :value="documents" :token="relatedDocFileToken"></st-file>
            </div>
        </div>
    </form>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import { BOverlay } from "bootstrap-vue";
import { RelatedApplicationModel } from "@/modules/applications/models/related-application-model";
import { FormSchema } from "@/shared/form/form-schema";
import { createFormValidation } from "@/shared/utils/create-form-validation";
import moment from 'moment';
import { generateRandomId } from '@/core/helpers/globalMethods';

const { fields } = RelatedApplicationModel;

const formSchema = new FormSchema([
    fields.id,
    fields.allocated_document_series,
    fields.issued_date,
    fields.issued_by,
    fields.related_document,
    fields.is_external,
]);

export default {
    name: "RelatedApplication",
    components: {
        StFile: () => import("@/shared/components/StFile"),
        "b-overlay": BOverlay,
    },
    props: {
        viewMode: {
            type: Boolean,
            default: false,
        },
        showLabel: {
            type: Boolean,
            default: true,
        },
        label: {
            type: String,
        },
        condition: {
            type: String,
        },
        docKey: {
            type: String,
            default: '',
        },
        customNotFoundMessage: {
            type: String,
            default: '',
        },
        autocompleteForm: {
            type: Boolean,
            default: false,
        },
        // if True, load all applications regardless of current user versus load only own applications
        loadGuestApplications: {
            type: Boolean,
            default: false,
        },
        // laod applications from all of t he organisations versus the typology organisation only
        loadAllOrganisationsApplications: {
            type: Boolean,
            default: false,
        },
        // load manually reserved series
        loadExternalDocuments: {
            type: Boolean,
            default: false,
        },
        // load manually reserved series
        forceShowDocumentUpload: {
            type: Boolean,
            default: false,
        },
        isRequired: {
            type: Boolean,
            default: false,
        },
        maxFileSize: {
            type: [Number, String],
            default: 10,
        },
        acceptedFileTypes: {
            type: Array,
            default: () => [],
        },
        maxFiles: {
            type: Number,
            default: 1,
        },
        wasValidated: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            fields: fields,
            model: null,
            rules: formSchema.rules(),
            fv: null,
            isExternalApplication: false,
            applicationNotFound: false,
            found: false,
            showLoading: false,
            searchValue: "",
            documents: [],
            isValid: false,
            formId: `st_related_application_form_${generateRandomId()}`,
            relatedDocFileToken: null,
            taxesField: null,
            options: [],
            externalDocumentsTracker: [],
        };
    },
    watch: {
        record() {
            this.initDocuments();
        },
    },
    computed: {
        ...mapGetters({
            record: "applications/form/record",
            currentUser: "auth/currentUser",
            isStaff: "auth/isStaff",
        }),
        showDocumentUpload() {
            if(this.loadExternalDocuments && !this.viewModeDocument && !this.forceShowDocumentUpload) {
                return false;
            }

            return this.applicationNotFound || this.documents?.length;
        },
        propName() {
            return `related_application${this.docKey}`;
        },
        propDocName() {
            return `related_application_document${this.docKey}`;
        },
        linkToIssuedDocuments() {
            return !this.loadGuestApplications
                && !!this.record?.form?.[this.propName]?.id
                && !this.record?.form?.[this.propName]?.is_external;
        },
        viewModeDocument() {
            return !this.applicationNotFound;
        },
        canUpload() {
            // do not allow upload when external documents or not owned applications are enabled in the form options
            return this.maxFiles > 0 && !this.loadGuestApplications && !this.loadExternalDocuments;
        },
        disabled() {
            return this.model[fields.issued_by.name] && !this.applicationNotFound;
        },
        validFileSign() {
            // show only when all applications are enabled and app is found
            return this.loadGuestApplications && !this.applicationNotFound;
        },
        searchCustomNotFoundMessage() {
            if(!this.showDocumentUpload && this.customNotFoundMessage.trim()) {
                return this.customNotFoundMessage;
            }
            return this.canUpload ? this.$t("RELATED_APPLICATION.NOT_FOUND_AND_UPLOAD") : this.$t("RELATED_APPLICATION.NOT_FOUND");
        },
    },
    methods: {
        ...mapActions({
            findApplicationByDocumentSeries: "applications/form/findByDocumentSeries",
            findExternalDocumentBySeries: "applications/form/findExternalDocumentBySeries",
            searchByDocumentSeries: "applications/form/searchByDocumentSeries",
            searchExternalDocumentsBySeries: "applications/form/searchExternalDocumentsBySeries",
        }),
        onInputChange({ selected: value }) {
            const isInputDirty = value !== this.searchValue;
            if (!isInputDirty) return false;
            if (!value || value === this.searchValue) {
                this.model = {
                    allocated_document_series: value,
                };
                this.applicationNotFound = true;
                this.found = false;
                return false;
            }

            let selectedDocIsExternal = false;
            this.showLoading = true;
            this.searchValue = value;

            let searchTarget = 'findApplicationByDocumentSeries';

            if(this.loadExternalDocuments) {
                selectedDocIsExternal = this.externalDocumentsTracker.some(v => v.value === value);
                if (selectedDocIsExternal) {
                    searchTarget = 'findExternalDocumentBySeries';
                }
            }

            this[searchTarget]({
                key: this.docKey,
                value,
                autocomplete: this.autocompleteForm,
                taxesField: this.taxesField,
                loadGuestApplications: this.loadGuestApplications || this.isStaff,
                currentUserId: this.currentUser.user_id,
            }).then((applicationData) => {
                const { data, token } = applicationData;

                this.relatedDocFileToken = token;

                let docs;
                if (data && data.files?.length > 0) {
                    this.found = true;
                    this.applicationNotFound = false;

                    this.model.id = data.id;
                    this.model.allocated_document_series = data.allocated_document_series;
                    this.model.issued_date = moment(data.issued_date).year();
                    this.model.issued_by = data.tenant_name;
                    this.model.is_external = selectedDocIsExternal;

                    docs = data.files ?? [];

                    let shouldHideFileViewIcon = selectedDocIsExternal;
                    if(!this.isStaff) {
                        shouldHideFileViewIcon = selectedDocIsExternal || data.applicant_user_id !== this.currentUser.user_id;
                    }
                    docs = docs.map(v => {return {...v, isHiddenForView: shouldHideFileViewIcon}});
                } else {
                    this.model = {
                        allocated_document_series: value,
                    };
                    this.applicationNotFound = true;
                    this.found = false;

                    docs = [];
                }
                this.documents = docs;
                this.onFileChange(docs);

                this.fv.validate();
                this.$emit("change", this.model, this.propName);
            })
            .catch((error) => {
                // TODO: Display a server error
                this.found = false;
                this.applicationNotFound = true;

                this.documents = [];
                this.onFileChange([]);
            })
            .finally(() => {
                this.showLoading = false;
            });
        },
        async updateOptions(value, addDefaultOption = true) {
            if (value.length > 0) {
                const defaultOption = {
                    value,
                    text: value,
                    description: this.$t('RELATED_APPLICATION.ISSUED_BY_DESCRIPTION'),
                    divider: true,
                };

                const limit = this.loadExternalDocuments ? 50 : 100;
                const results = await this.searchByDocumentSeries({
                    value,
                    loadGuestApplications: this.loadGuestApplications,
                    loadAllOrganisationsApplications: this.loadAllOrganisationsApplications,
                    limit,
                });

                let externalDocumentResults = [];
                if(this.loadExternalDocuments) {
                    externalDocumentResults = await this.searchExternalDocumentsBySeries({value});
                    this.externalDocumentsTracker = [
                        ...externalDocumentResults.map(v => {return {value: v.allocated_document_series, is_external: true}}),
                    ];
                }

                const options = (results ?? []).concat(externalDocumentResults ?? []).map((row) => ({
                    value: row.allocated_document_series,
                    text: row.allocated_document_series,
                    description: this.$t('RELATED_APPLICATION.ISSUED_BY_SYSTEM_DESCRIPTION', { tenant: row.tenant_name }),
                    id: row.id,
                }));

                // do not add default options when non-owned applications are loaded
                if (addDefaultOption && !this.loadGuestApplications) {
                    return [ defaultOption, ...options ]
                }

                return options;
            }
        },
        onFileChange(document) {
            this.isValid = !!document.length;
            this.fv.validate();
            this.$emit("change", document, this.propDocName);
        },
        initDocuments() {
            this.documents = [];
            if (this.record) {
                this.documents = this.record?.form?.[this.propDocName]?.length
                    ? (this.record.form[this.propDocName] ?? []).map(v => {
                        return {...v, isHiddenForView: this.isStaff ? false : v.isHiddenForView}
                    })
                    : [];
                this.isValid = !!this.documents.length;
                this.model = formSchema.initialValues({
                    ...this.record?.form?.[this.propName],
                });
                if (this.record.form?.[this.propName]?.allocated_document_series && !this.record.form?.[this.propName]?.id) {
                    this.applicationNotFound = true;
                }
            } else {
                this.model = formSchema.initialValues({});
            }
        },
        validateField(field) {
            this.fv.validateField(field);
        },
        isDocumentUploadedValid() {
            if (this.applicationNotFound) {
                return this.isValid;
            }
            return true;
        },
        goToLink() {
            this.$router.push({ name: 'issuedDocuments', query: {
                number: this.record.form?.[this.propName]?.allocated_document_series,
            } })
        },
    },
    async created() {
        this.initDocuments();
        if (this.condition && this.condition.includes('=')) {
            const values = this.condition.split('=');
            if (values.length > 1 && values[0].trim() === 'taxes_field') {
                this.taxesField = values[1].trim();
            }
        }
        if (!this.viewMode) {
            // const searchKey = `${this.docKey}` ?? "20";
            const searchKey = "20";
            this.options = await this.updateOptions(searchKey, false);
        }
    },
    mounted() {
        this.fv = createFormValidation(
            this.formId,
            this.rules
        );
    },
};
</script>

<style scoped>
    .valid-file {
        font-size: 2rem;
    }
</style>
